import _ from 'lodash';

import storage from './storage';

const transformKeysRecursive = (obj, transformFunc) => _.transform(obj, (acc, value, key, target) => {
  const camelKey = _.isArray(target) ? key : transformFunc(key);
  acc[camelKey] = _.isObject(value) ? transformKeysRecursive(value, transformFunc) : value;
});

const toSnakeCase = _.partial(transformKeysRecursive, _, _.snakeCase);
const toCamelCase = _.partial(transformKeysRecursive, _, _.camelCase);

class APIError extends Error {
  constructor(response, responseData, ...args) {
    super(`HTTP Error Response: ${response.status} ${response.statusText}`, ...args);
    this.response = response;
    this.responseData = responseData;
  }

  userMessage() {
    const message = this.responseData && this.responseData.detail;
    return !_.isArray(message) ? message : null;
  }
}

const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

const makeRequest = async ({ method, path, headers, body } = {}) => {
  const args = { method };

  args.headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers || {},
  );

  if (typeof body !== 'undefined') {
    args.body = JSON.stringify(toSnakeCase(body));
  }

  const host = process.env.REACT_APP_CANVASS_API_HOSTNAME;
  const url = `${host}${path}`;
  const response = await fetch(url, args);
  let data = await response.text();
  const parsed = parseData(data);

  if (!response.ok) {
    throw new APIError(response, parsed);
  }

  if (!parsed) {
    return;
  }
  return toCamelCase(parsed);
};

const putCached = (shortcode, interview) => {
  const cacheKey = `interview-${shortcode}`;
  const serialised = JSON.stringify(interview);
  storage.setItem(cacheKey, serialised);
};

const getCached = (shortcode) => {
  const cacheKey = `interview-${shortcode}`;
  const cached = storage.getItem(cacheKey);
  return cached ? JSON.parse(cached) : null;
};

const fetchCandidateInterview = async (shortcode, allowCached) => {
  if (allowCached) {
    const cached = getCached(shortcode);
    if (cached) {
      return cached;
    }
  }

  const response = await makeRequest({
    method: 'get',
    path: `/api/v1/interviews/${shortcode}`,
  });
  putCached(shortcode, response);
  return response;
};

const fetchTheme = async shortcode =>
  await makeRequest({
    method: 'get',
    path: `/api/v1/interviews/${shortcode}/theme`,
  });

const createApplication = async (shortcode, interviewId, candidateName, email) =>
  await makeRequest({
    method: 'post',
    path: `/api/v1/applications`,
    body: { shortcode, interviewId, candidateName, email },
  });

const saveAnswer = (shortcode, index, answer) => {
  const storageKey = `answers-${shortcode}`;
  const answers = getSavedAnswers(shortcode);
  answers[index] = answer;
  storage.setItem(storageKey, JSON.stringify(answers));
};

const getSavedAnswers = shortcode => {
  const storageKey = `answers-${shortcode}`;
  const rawAnswers = storage.getItem(storageKey);
  return rawAnswers ? JSON.parse(rawAnswers) : [];
};

const flushSavedAnswers = shortcode => {
  const storageKey = `answers-${shortcode}`;
  storage.removeItem(storageKey);
};

const submitInterview = async (shortcode, tookSeconds) => {
  const answers = getSavedAnswers(shortcode);
  const newResponse = { answers, tookSeconds };
  const candidateInterview = await makeRequest({
    method: 'put',
    path: `/api/v1/interviews/${shortcode}`,
    body: newResponse,
  });
  flushSavedAnswers(shortcode);
  const cachedInterview = getCached(shortcode);
  if (cachedInterview) {
    cachedInterview.candidateInterview = candidateInterview;
    putCached(shortcode, cachedInterview);
  }
};

const createMediaUpload = async (shortcode, mediaGroupId, contentType, properties) => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/candidate-media-uploads`,
    body: { shortcode, mediaGroupId, contentType, properties },
  });
};

const createLiveRecording = async shortcode => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/mux-live/candidate-recordings`,
    body: { shortcode },
  });
};

const startLiveRecording = async recordingId => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/mux-live/recordings/${recordingId}/start`,
  });
};

const stopLiveRecording = async recordingId => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/mux-live/recordings/${recordingId}/stop`,
  });
};

const completeMediaUploads = async (mediaIds, preferTranscoder) => {
  const qs = preferTranscoder ? `?prefer_transcoder=${preferTranscoder}` : '';
  await makeRequest({
    method: 'put',
    path: `/api/v1/media-uploads/complete${qs}`,
    body: { mediaIds },
  });
};

const saveDetails = (shortcode, details) => {
  const storageKey = `details-${shortcode}`;
  storage.setItem(storageKey, JSON.stringify(details));
};

const getSavedDetails = shortcode => {
  const storageKey = `details-${shortcode}`;
  const rawDetails = storage.getItem(storageKey);
  return rawDetails ? JSON.parse(rawDetails) : { candidateName: '', email: '' };
};

const flushSavedDetails = shortcode => {
  const storageKey = `details-${shortcode}`;
  storage.removeItem(storageKey);
};

const submitApplication = async (shortcode, interviewId, tookSeconds) => {
  const answers = getSavedAnswers(shortcode);
  const savedDetails = getSavedDetails(shortcode);
  const newApplication = { interviewId, answers, tookSeconds, ...savedDetails };
  const candidateInterview = await makeRequest({
    method: 'put',
    path: `/api/v1/applications/${shortcode}`,
    body: newApplication,
  });
  flushSavedAnswers(shortcode);
  flushSavedDetails(shortcode);
  const cachedInterview = getCached(shortcode);
  if (cachedInterview) {
    cachedInterview.candidateInterview = candidateInterview;
    putCached(shortcode, cachedInterview);
  }
};

const api = {
  fetchCandidateInterview,
  fetchTheme,
  createApplication,
  saveAnswer,
  getSavedAnswers,
  flushSavedAnswers,
  submitInterview,
  createMediaUpload,
  createLiveRecording,
  startLiveRecording,
  stopLiveRecording,
  completeMediaUploads,
  saveDetails,
  getSavedDetails,
  submitApplication,
};

export default api;
