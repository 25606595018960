import _ from 'lodash';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router';
import InterviewPage from '../InterviewPage';
import CenteredLoading from '../CenteredLoading';
import VideoPlayer from '../VideoPlayer';
import utils from '../../utils';
import useCandidateInterview from '../../hooks/useCandidateInterview';

const OutroVideo = ({ thumbnail, video }) => {
  const { thumbnailHref, videoSources, captionsHref } = utils.extractHrefsForVideoPlayer(thumbnail, video);
  const videoPlayer = useMemo(() =>
    <Box style={{ borderRadius: '4px', overflow: 'hidden' }}>
      <VideoPlayer
        options={{
          controls: true,
          fluid: true,
          poster: thumbnailHref,
          sources: videoSources,
          controlBar: {
            volumePanel: false,
            pictureInPictureToggle: false,
          },
        }}
        captionsHref={captionsHref}
        videoTagClassNames={['vjs-big-play-centered']}
      />
    </Box>,
    [thumbnailHref, videoSources, captionsHref],
  );
  return videoPlayer;
};

const OutroPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const { shortcode } = useParams();
  const { candidateInterview } = useCandidateInterview(shortcode);

  if (!candidateInterview) {
    return <CenteredLoading message={t('Loading outro...')} />
  }

  const outro = _.cloneDeep(candidateInterview.outro || {});
  outro.title = outro.title || t('🎉 Thank you!');
  outro.content = outro.content || t("We'll review and be in touch shortly.");
  const hasOutroVideo = !!outro.thumbnail && !!outro.video;

  return (
    <InterviewPage maxWidth={hasOutroVideo ? "lg" : "md"}>
      <Box pl={isExtraSmall ? 3 : 0} pr={isExtraSmall ? 3 : 0}>
        <Grid container spacing={2}>
          {
            hasOutroVideo &&
            <Grid item xs={12} sm={6}>
              <OutroVideo thumbnail={outro.thumbnail} video={outro.video} />
            </Grid>
          }
          <Grid item xs={12} sm={hasOutroVideo ? 6 : 12}>
            <Box
              mt={isExtraSmall ? 2 : 0}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              style={{ minHeight: "100%" }}
            >
              <Typography variant="h5" gutterBottom>
                {outro.title}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {outro.content}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </InterviewPage>
  );
};

export default OutroPage;