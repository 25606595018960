import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Box, Button, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useParams } from 'react-router';

import api from '../../API';
import useCandidateInterview from '../../hooks/useCandidateInterview';
import InterviewPage from '../InterviewPage';

const DetailsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { shortcode } = useParams();
  const { candidateInterview } = useCandidateInterview(shortcode);
  const savedDetails = api.getSavedDetails(shortcode);
  const [candidateName, setCandidateName] = useState(savedDetails.candidateName);
  const [email, setEmail] = useState(savedDetails.email);
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClickContinue = async () => {
    setIsCreating(true);
    setCreateError(null);
    try {
      const trimmedName = candidateName.trim();
      const trimmedEmail = email.trim();
      api.saveDetails(shortcode, { candidateName: trimmedName, email: trimmedEmail })
      const newApplicantResponse = await api.createApplication(
        shortcode,
        candidateInterview.interviewId,
        trimmedName,
        trimmedEmail
      );
      history.push(`/interview/${newApplicantResponse.shortcode}/select-audio-video`);
    } catch (error) {
      setIsCreating(false);
      setCreateError(error);
    }
  };

  const canContinue = candidateName.trim().length > 0 && email.trim().length > 0;

  return (
    <InterviewPage maxWidth="sm">
      <Box pl={isExtraSmall ? 3 : 0} pr={isExtraSmall ? 3 : 0}>
        <Box mb={3} textAlign="center">
          <Typography variant="h6" gutterBottom>
            {t('Provide your contact details')}
          </Typography>
          <Typography variant="body1">
            {t("We'll use these to be in touch after reviewing your submission.")}
          </Typography>
          {
            // n.b., this is for ISB parent interview
            candidateInterview && candidateInterview.positionId == '6df41e1a-2631-4cd3-aa13-b2b5226b3ce1' &&
            <Typography variant="body1">
              {t("Please enter below the name of the student applying, and use the email address that you used to apply.")}
            </Typography>
          }
        </Box>
        {
          createError &&
          <Box mb={3}>
            <Alert severity="error">
              <AlertTitle>{t("Unable to initiate interview")}</AlertTitle>
              {
                createError.userMessage() || <Trans>
                  Please try again and contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
                  assistance if the problem persists.
                </Trans>
              }
            </Alert>
          </Box>
        }
        <Box>
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            label={t('Full name')}
            value={candidateName}
            onChange={event => setCandidateName(event.target.value)}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            type="email"
            variant="outlined"
            label={t('Email address')}
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </Box>
        <Box textAlign="center" mt={2}>
          <Button size="large" variant="contained" color="primary" onClick={handleClickContinue} disabled={isCreating || !canContinue}>
            {isCreating ? t('Saving...') : t('Continue')}
          </Button>
        </Box>
      </Box>
    </InterviewPage>
  );
};

export default DetailsPage;