import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router';
import {
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import { Box, ThemeProvider } from '@material-ui/core';
import { styled, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import api from './API';
import defaultTheme from './theme';
import IntroPage from './components/pages/IntroPage';
import DetailsPage from './components/pages/DetailsPage';
import SelectAudioVideoPage from './components/pages/SelectAudioVideoPage';
import QuestionPage from './components/pages/QuestionPage';
import OutroPage from './components/pages/OutroPage';
import LoadInterviewPage from './components/pages/LoadInterviewPage';
import { LogoContext } from './context';

const CompanyThemed = ({ children }) => {
  const [customTheme, setCustomTheme] = useState({ isResolved: false });
  const { shortcode } = useParams();

  const fetchCustomTheme = useCallback(async () => {
    try {
      const response = await api.fetchTheme(shortcode);
      if (response.theme) {
        setCustomTheme({
          isResolved: true,
          theme: createTheme(response.theme.themeDefinition),
          logoImageHref: response.theme.logoImageGroup
            ? response.theme.logoImageGroup.variants[0].contentHref
            : null,
        });
      } else {
        // Successfully retrieved but no custom theme
        setCustomTheme({
          isResolved: true
        });
      }
    } catch (e) {
      // Error retrieving and assume no custom theme
      setCustomTheme({
        isResolved: true
      });
    }
  }, [setCustomTheme, shortcode]);

  useEffect(() => fetchCustomTheme(), [fetchCustomTheme]);

  if (!customTheme.isResolved) {
    return null;
  }

  return (
    <ThemeProvider theme={!!customTheme.theme ? customTheme.theme : defaultTheme}>
      <LogoContext.Provider value={customTheme.logoImageHref}>
        {children}
      </LogoContext.Provider>
    </ThemeProvider>
  );
};

const ThemedBackground = styled(({ children, ...props }) => {
  return (
    <Box width="100%" height="100%" {...props}>
      {children}
    </Box>
  );
})(props => ({
  backgroundColor: props.theme.palette.background.default,
}));

const App = () => {
  return (
    <>
      <CssBaseline />
      <Switch>
        <Route path="/interview/:shortcode" render={props => (
          <CompanyThemed>
            <ThemedBackground>
              <Switch>
                <Route
                  path={`${props.match.path}/intro`}
                  component={() => <IntroPage />}
                />
                <Route
                  path={`${props.match.path}/details`}
                  component={() => <DetailsPage />}
                />
                <Route
                  path={`${props.match.path}/select-audio-video`}
                  component={() => <SelectAudioVideoPage />}
                />
                <Route
                  path={`${props.match.path}/questions/:index`}
                  component={() => <QuestionPage />}
                />
                <Route
                  path={`${props.match.path}/outro`}
                  component={() => <OutroPage />}
                />
              </Switch>
            </ThemedBackground>
          </CompanyThemed>
        )}
        />
        <Route
          path="/:shortcode"
          component={() => (
            <CompanyThemed>
              <ThemedBackground>
                <LoadInterviewPage />
              </ThemedBackground>
            </CompanyThemed>
          )}
        />
      </Switch>
    </>
  );
};

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <App />
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

export default startApp;
