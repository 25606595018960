import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0a5055',
    },
    secondary: {
      main: '#c35252',
      contrastText: '#ffffff',
    },
    background: {
      default: '#fbf1f0',
      paper: '#fafafa',
    },
    text: {
      primary: 'rgba(21,21,21,0.87)',
      secondary: 'rgba(21,21,21,0.54)',
      disabled: 'rgba(21,21,21,0.38)',
      hint: 'rgba(21,21,21,0.38)',
    },
  },
});