import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Bowser from "bowser";
import getBrowserRtc from 'get-browser-rtc';
import { styled } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import StopIcon from '@material-ui/icons/Stop';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Space, SpaceEvent } from '@mux/spaces-web';
import { useParams } from 'react-router';
import Webcam from "react-webcam";
import CenteredContent from '../CenteredContent';
import CenteredLoading from '../CenteredLoading';
import InterviewPage from '../InterviewPage';
import VideoPlayer from '../VideoPlayer';
import utils from '../../utils';
import useCheckMediaRecorder from '../../hooks/useCheckMediaRecorder';
import useCandidateInterview from '../../hooks/useCandidateInterview';
import api from '../../API';
import storage from '../../storage';
import { trackError, trackErrorMessage } from '../../errors';

const WEBRTC_SUPPORT = !!getBrowserRtc();

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Countdown = styled(({ startingCount, pending, active, onFinish, ...props }) => {
  const [count, setCount] = useState(startingCount);

  const nextTick = useCallback(() => {
    if (count <= 1) {
      onFinish();
    } else {
      setCount(currentCount => currentCount - 1);
    }
  }, [count, onFinish, setCount]);

  useEffect(() => {
    let timerId;
    if (active) {
      timerId = setInterval(nextTick, 1000);
    }
    return () => clearInterval(timerId);
  }, [active, nextTick]);

  return (
    <Backdrop open={pending || active} {...props}>
      {pending && <CircularProgress color="inherit" />}
      {active && <>{count || null}</>}
    </Backdrop>
  );
})({
  zIndex: 1099,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  fontSize: '80px',
  color: 'white',
});

const BASE_AUDIO_CONSTRAINTS = {};

const BASE_VIDEO_CONSTRAINTS = {
  facingMode: 'user',
};

const THUMBNAIL_CONTENT_TYPES = ['image/png'];
const SCREENSHOT_QUALITY = 1;
const IMAGE_SMOOTHING_ENABLED = true;

const getScreenshots = webcam => {
  // Don't use the getScreenshot() method of the webcam directly
  // because it'll be the mirror of the video recording...
  const { video } = webcam;
  const canvas = webcam.getCanvas();
  const ctx = canvas.getContext("2d");
  ctx.imageSmoothingEnabled = IMAGE_SMOOTHING_ENABLED;
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  const screenshots = THUMBNAIL_CONTENT_TYPES.map(
    contentType => ({
      contentType,
      base64Data: canvas.toDataURL(contentType, SCREENSHOT_QUALITY),
      properties: {
        width: canvas.width,
        height: canvas.height,
      },
    })
  );
  return screenshots;
};

const toMMSS = (elapsedSeconds) => {
  var minutes = Math.floor((elapsedSeconds) / 60);
  var seconds = elapsedSeconds - (minutes * 60);
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return minutes + ':' + seconds;
};

const CircularProgressWithLabel = ({ progress, elapsedSeconds }) =>
  <Box position="relative" display="inline-flex">
    <CircularProgress variant="determinate" color="secondary" thickness={5} size={55} value={progress} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="button" component="div" color="secondary">
        <Box fontWeight="bold">
          {toMMSS(elapsedSeconds)}
        </Box>
      </Typography>
    </Box>
  </Box>;

const RecordingIndicator = styled(({ pacing, recordedTime, ...props }) => {
  const recordedPercentageOfAllowed = (parseFloat(recordedTime) / pacing.answerTime) * 100;
  const countdownProgress = 100 - Math.min(recordedPercentageOfAllowed, 100);
  return (
    <Box {...props}>
      <CircularProgressWithLabel progress={countdownProgress} elapsedSeconds={pacing.answerTime - recordedTime} />
    </Box>
  );
})(props => ({
  position: 'absolute',
  top: `${props.theme.spacing(2)}px`,
  right: `${props.theme.spacing(2)}px`,
}));

const RecordingStop = styled(({ onClickStop, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" {...props}>
      <Button size="large" variant="contained" color="secondary" startIcon={<StopIcon />} onClick={onClickStop}>
        {t('Stop')}
      </Button>
    </Box>
  );
})(props => ({
  position: 'absolute',
  bottom: `${props.theme.spacing(2)}px`,
  left: '0px',
  right: '0px',
}));

const ConfirmAnswerControls = styled(({ retakesRemaining, isFinalQuestion, onClickRetake, onClickNext, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="flex-end" {...props}>
      <Box display="flex" flexDirection="column">
        {
          retakesRemaining &&
          <Box color="white" textAlign="center">
            <Typography variant="overline">
              <Trans>
                {{ numberRemaining: retakesRemaining }} remaining
              </Trans>
            </Typography>
          </Box>
        }
        <Button size="large" variant="contained" color="secondary" startIcon={<ReplayIcon />} onClick={onClickRetake}>
          {t('Retake')}
        </Button>
      </Box>
      <Box ml={1.5}>
        <Button size="large" variant="contained" color="secondary" endIcon={<ArrowForwardIcon />} onClick={onClickNext}>
          {isFinalQuestion ? t('Finish') : t('Next')}
        </Button>
      </Box>
    </Box>
  );
})(props => ({
  position: "absolute",
  bottom: `${props.theme.spacing(2)}px`,
  left: '0px',
  right: '0px',
  zIndex: 1100,
}));

const ConfirmAnswer = styled(({ open, retakesRemaining, isFinalQuestion, onClickRetake, onClickNext, ...props }) => {
  return (
    <Backdrop open={open} {...props}>
      <ConfirmAnswerControls
        retakesRemaining={retakesRemaining}
        isFinalQuestion={isFinalQuestion}
        onClickRetake={onClickRetake}
        onClickNext={onClickNext}
      />
    </Backdrop>
  );
})({
  zIndex: 1099,
  position: 'absolute',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
});

const AnswerQuestion = ({ isPreview, preferTranscoder, shortcode, questionIndex, isFinalQuestion, attemptNumber, pacing, onRetakeQuestion, onFinishAnswer }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const webcamRef = useRef(null);
  const recorderRef = useRef(null);
  const [mediaStream, setMediaStream] = useState();
  const [isConnecting, setIsConnecting] = useState(true);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [isConnectingToRecorderService, setIsConnectingToRecorderService] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isStoppingRecording, setIsStoppingRecording] = useState(false);
  const [recordedTime, setRecordedTime] = useState(0);
  const [recordedMedia, setRecordedMedia] = useState();
  const [isConfirmingAnswer, setIsConfirmingAnswer] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [connectError, setConnectError] = useState();
  const [recorderServiceError, setRecorderServiceError] = useState();
  const [saveError, setSaveError] = useState();
  const [recording, setRecording] = useState();
  const [space, setSpace] = useState();
  const [participant, setParticipant] = useState();
  const [isStoppedRecording, setIsStoppedRecording] = useState();
  const [notifyStoppedRecording, setNotifyStoppedRecording] = useState();
  const {
    error: recorderError,
    contentType: supportedContentType,
  } = useCheckMediaRecorder();

  const shouldForceRecorderService = storage.getItem('canvass-force-recorder-service') === 'true';
  const shouldUseRecorderService = WEBRTC_SUPPORT && !isPreview && (
    shouldForceRecorderService || !supportedContentType
  );

  const audioConstraints = useMemo(() => {
    const constraints = { ...BASE_AUDIO_CONSTRAINTS };

    const audioDeviceId = storage.getItem('canvass-audio-device-id');
    if (audioDeviceId) {
      constraints.deviceId = audioDeviceId;
    }

    return constraints;
  }, []);

  const videoConstraints = useMemo(() => {
    const constraints = { ...BASE_VIDEO_CONSTRAINTS };

    const browserSettings = Bowser.parse(window.navigator.userAgent);
    if (browserSettings?.platform?.type === 'desktop') {
      constraints.width = 852;
      constraints.height = 480;
      constraints.aspectRatio = 1.777777778;
    }

    const videoDeviceId = storage.getItem('canvass-video-device-id');
    if (videoDeviceId) {
      constraints.deviceId = videoDeviceId;
    }

    return constraints;
  }, []);

  const hasUnlimitedRetakes = _.isUndefined(pacing.retakes) || _.isNull(pacing.retakes) || pacing.retakes < 0;
  const retakesRemaining = !hasUnlimitedRetakes
    ? pacing.retakes - attemptNumber + 1
    : null;
  const canRetake = hasUnlimitedRetakes || retakesRemaining > 0;

  useEffect(() => {
    if (!isConnecting) {
      const notifyWrapper = {};
      setIsStoppedRecording(new Promise(resolve => {
        notifyWrapper.notify = () => resolve(true);
      }));
      setNotifyStoppedRecording(notifyWrapper);
    }
  }, [isConnecting, setIsStoppedRecording, setNotifyStoppedRecording]);

  const stopRecording = useCallback(async () => {
    setIsStoppingRecording(true);
    setIsRecording(false);

    if (shouldUseRecorderService) {
      await api.stopLiveRecording(recording.recordingId);
    }

    if (recorderRef.current) {
      recorderRef.current.stop();
      recorderRef.current = null;
    }

    notifyStoppedRecording.notify();
  }, [recorderRef, setIsRecording, setIsStoppingRecording, shouldUseRecorderService, recording, notifyStoppedRecording]);

  const nextTick = useCallback(() => {
    if (isRecording) {
      if (recordedTime >= pacing.answerTime) {
        stopRecording();
      } else {
        setRecordedTime(currentTime => currentTime + 1);
      }
    }
  }, [isRecording, recordedTime, pacing, stopRecording, setRecordedTime]);

  useEffect(() => {
    const timerId = setInterval(nextTick, 1000);
    return () => clearInterval(timerId);
  }, [nextTick]);

  const connectToRecorderService = async mediaStream => {
    setIsConnectingToRecorderService(true);

    let newRecording, newSpace, newParticipant;
    try {
      newRecording = await api.createLiveRecording(shortcode);
      newSpace = new Space(newRecording.muxSpaceJwt);
      newParticipant = await newSpace.join();

      const localTracks = mediaStream.getTracks().map(mediaTrack =>
        newParticipant.localTrackFromMediaTrack(
          mediaTrack,
          false, /* Not a screen share... */
        )
      );
      await newParticipant.publishTracks(localTracks);
    } catch (error) {
      trackError(error);
      setRecorderServiceError(t('Unable to connect to recorder'));
      return;
    }

    setRecording(newRecording);
    setSpace(newSpace);
    setParticipant(newParticipant);

    try {
      // There's few seconds lag before stream is actually captured, so
      // initiate recording before starting the countdown...
      await api.startLiveRecording(newRecording.recordingId);
    } catch (error) {
      trackError(error);
      setRecorderServiceError(t('Unable to start recording'));
      return;
    }

    setIsConnectingToRecorderService(false);
    setIsCountingDown(true);
  };

  const handleUserMedia = mediaStream => {
    setIsConnecting(false);
    setMediaStream(mediaStream);

    if (shouldUseRecorderService) {
      connectToRecorderService(mediaStream);
    } else {
      setIsCountingDown(true);
    }
  };

  const handleUserMediaError = (error) => {
    setIsConnecting(false);
    setConnectError(error);
  };

  const handleSaveAndGoNextQuestion = () => {
    const { thumbnailScreenshots, videos } = recordedMedia;
    trySaving(thumbnailScreenshots, videos);
  };

  const trySaving = async (thumbnailScreenshots, videos) => {
    setIsConfirmingAnswer(false);
    setSaveError(null);
    setIsSaving(true);
    const [success, result] = await saveMediaToBackend(thumbnailScreenshots, videos);
    if (success) {
      onFinishAnswer(result);
    } else {
      setSaveError(result);
    }
  };

  const handleCountdownFinish = async () => {
    setIsCountingDown(false);
    setIsRecording(true);

    const thumbnailScreenshots = getScreenshots(webcamRef.current);

    const videoTracks = mediaStream.getVideoTracks();
    const videoSettings = videoTracks[0].getSettings();

    const audioTracks = mediaStream.getAudioTracks();
    const audioSettings = audioTracks[0].getSettings();

    const browserSettings = Bowser.parse(window.navigator.userAgent);

    const videoPromises = [];

    if (shouldUseRecorderService) {
      videoPromises.push(
        new Promise(resolve => {
          space.on(SpaceEvent.BroadcastStateChanged, isBroadcasting => {
            if (!isBroadcasting) {
              resolve(_.pick(recording, ['videoId', 'videoGroupId']));
              participant.unpublishAllTracks().then(() => {
                space.leave();
              });
            }
          });
        })
      );
    }

    if (supportedContentType) {
      // MediaRecorder can be tempermental so don't actually force it to use
      // the content type we've deemed as best supported... It's good enough to 
      // simply know that there is a supported type going in then see what we get...
      recorderRef.current = new MediaRecorder(webcamRef.current.stream);

      const recordedBlobs = [];
      recorderRef.current.addEventListener(
        'dataavailable',
        event => {
          if (event.data && event.data.size > 0) {
            recordedBlobs.push(event.data);
          }
        }
      );

      videoPromises.push(
        new Promise(resolve => {
          recorderRef.current.addEventListener(
            'stop', () => {
              const combinedBlob = new Blob(recordedBlobs, {
                type: recordedBlobs.length > 0 ? recordedBlobs[0].type : supportedContentType
              });
              resolve({ data: combinedBlob });
            },
          );
        })
      );

      recorderRef.current.start(250);
    }

    let videos = [];
    if (videoPromises.length > 0) {
      const videoData = await Promise.all(videoPromises);
      const videoMetadata = { videoSettings, audioSettings, browserSettings };
      videos = videoData.map(result => {
        if (result.videoId) {
          return {
            ...result,
            ...videoMetadata,
          };
        }
        return {
          blobData: result.data,
          videoSize: result.data.size,
          ...videoMetadata,
        }
      });
    } else {
      // In preview mode we may not be recording any videos to await on and so
      // need to rely on another artificial 'finish' signal...
      await isStoppedRecording;
    }

    setIsStoppingRecording(false);
    setRecordedMedia({ thumbnailScreenshots, videos });

    if (canRetake) {
      setIsConfirmingAnswer(true);
    } else {
      await trySaving(thumbnailScreenshots, videos);
    }
  };

  const augmentWithAttemptMetadata = (properties) => {
    return _.extend(properties, {
      "shortcode": shortcode,
      "questionIndex": questionIndex,
      "attemptNumber": attemptNumber
    });
  };

  const saveMediaToBackend = async (thumbnailScreenshots, videos) => {
    if (isPreview) {
      // Make the preview feel realistic
      await timeout(2000);
      const previewResult = {
        thumbnailImageGroupId: uuidv4(),
        videoGroupId: uuidv4(),
      };
      return [true, previewResult];
    }

    const thumbnailErrors = new Set();
    try {
      const thumbnailImageGroupId = uuidv4();
      const thumbnailMediaIds = [];
      for (const { base64Data, contentType, properties } of thumbnailScreenshots) {
        const thumbnailBlob = await fetch(base64Data).then(r => r.blob());
        if (thumbnailBlob.size <= 0) {
          thumbnailErrors.add(t('Empty file encountered'));
          continue;
        }
        augmentWithAttemptMetadata(properties);
        const thumbnailDetails = await api.createMediaUpload(
          shortcode, thumbnailImageGroupId, contentType, properties,
        );
        const thumbnailResponse = await fetch(thumbnailDetails.uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': contentType,
          },
          body: thumbnailBlob,
        });
        if (!thumbnailResponse.ok) {
          thumbnailErrors.add(t('Upload failure'));
          continue;
        }
        thumbnailMediaIds.push(thumbnailDetails.mediaId);
      }

      if (thumbnailMediaIds.length <= 0) {
        const thumbnailErrorMessage = [t('Unable to capture thumbnail'), ...thumbnailErrors].join('. ') + '.';
        trackErrorMessage(thumbnailErrorMessage);
        return [false, thumbnailErrorMessage];
      }

      const videoGroupIds = _.filter(videos.map(video => video.videoGroupId));
      const videoGroupId = videoGroupIds.length > 0 ? videoGroupIds[0] : uuidv4();
      const videoMediaIds = [];
      const videoErrors = new Set();
      for (const video of videos) {
        const properties = augmentWithAttemptMetadata(_.pick(
          video,
          ['videoSize', 'audioSettings', 'videoSettings', 'browserSettings'],
        ));

        if (video.videoId) {
          videoMediaIds.push(video.videoId);
        } else {
          if (video.blobData.size <= 0) {
            videoErrors.add(t('Empty file encountered'));
            continue;
          }
          const videoDetails = await api.createMediaUpload(
            shortcode, videoGroupId, video.blobData.type, properties,
          );
          const videoResponse = await fetch(videoDetails.uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': video.blobData.type,
            },
            body: video.blobData,
          });
          if (!videoResponse.ok) {
            videoErrors.add(t('Upload failure'));
            continue;
          }
          videoMediaIds.push(videoDetails.mediaId);
        }
      }

      if (videoMediaIds.length <= 0) {
        const videoErrorMessage = [t('Unable to record video'), ...videoErrors].join('. ') + '.';
        trackErrorMessage(videoErrorMessage);
        return [false, videoErrorMessage];
      }

      const mediaIds = [...videoMediaIds, ...thumbnailMediaIds];
      await api.completeMediaUploads(mediaIds, preferTranscoder);

      return [true, { thumbnailImageGroupId, videoGroupId }];
    } catch (error) {
      trackError(error);
      return [false, t('Unable to save video')];
    }
  };

  const unableToRecord = (recorderError && !shouldUseRecorderService) || recorderServiceError;
  if (!isPreview && unableToRecord) {
    return (
      <CenteredContent>
        <Alert severity="error">
          <AlertTitle>{recorderError || recorderServiceError}</AlertTitle>
          <Trans>
            We're unable to record video in your browser. Please try another
            browser or device. Don't hesitate to contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
            further assistance.
          </Trans>
        </Alert>
      </CenteredContent>
    );
  }

  if (connectError) {
    return (
      <CenteredContent>
        <Alert severity="error">
          <AlertTitle>{connectError.message}</AlertTitle>
          <Trans>
            Unable to access your camera or microphone. Please ensure that you've
            granted the necessary permissions in your browser settings. Don't
            hesitate to contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
            further assistance.
          </Trans>
        </Alert>
      </CenteredContent>
    );
  }

  return (
    <>
      <InterviewPage maxWidth={"md"}>
        <Box pl={isExtraSmall ? 3 : 0} pr={isExtraSmall ? 3 : 0}>
          <Box display={(isConnecting || isSaving) ? "none" : "block"} position='relative' fontSize={0}>
            {
              !isSaving &&
              <Webcam
                muted
                ref={webcamRef}
                audio={true}
                imageSmoothing={IMAGE_SMOOTHING_ENABLED}
                mirrored={true}
                audioConstraints={audioConstraints}
                forceScreenshotSourceSize={true}
                screenshotFormat={THUMBNAIL_CONTENT_TYPES[0]} // Not actually used
                screenshotQuality={SCREENSHOT_QUALITY} // Not actually used
                videoConstraints={videoConstraints}
                onUserMedia={handleUserMedia}
                onUserMediaError={handleUserMediaError}
                width='100%'
              />
            }
            <Countdown startingCount={-1} pending={isStoppingRecording} active={false} />
            <Countdown startingCount={3} pending={isConnectingToRecorderService} active={isCountingDown} onFinish={handleCountdownFinish} />
            {
              isRecording &&
              <>
                <RecordingIndicator pacing={pacing} recordedTime={recordedTime} />
                <RecordingStop onClickStop={stopRecording} />
              </>
            }
            <ConfirmAnswer
              open={isConfirmingAnswer}
              retakesRemaining={retakesRemaining}
              isFinalQuestion={isFinalQuestion}
              onClickRetake={onRetakeQuestion}
              onClickNext={handleSaveAndGoNextQuestion}
            />
          </Box>
          {
            isConnecting &&
            <QuestionProgress message={t('Connecting to your camera...')} />
          }
          {
            isSaving &&
            <QuestionProgress message={t('Saving your video...')} />
          }
        </Box>
      </InterviewPage>
      <Dialog open={!!saveError}>
        <DialogTitle>{saveError}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              Unable to save your video to our platform. Please try recording from another device
              and contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
              assistance if the problem persists.
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveAndGoNextQuestion} color="primary">
            {t('Try again')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const QuestionVideo = ({ thumbnail, video, onVideoEnded }) => {
  const { thumbnailHref, videoSources, captionsHref } = utils.extractHrefsForVideoPlayer(thumbnail, video);

  const handleReady = useCallback(player => {
    player.on('ended', onVideoEnded);
  }, [onVideoEnded]);

  const videoPlayer = useMemo(() =>
    <VideoPlayer
      options={{
        controls: true,
        fluid: true,
        poster: thumbnailHref,
        sources: videoSources,
        controlBar: {
          volumePanel: false,
          pictureInPictureToggle: false,
        },
      }}
      captionsHref={captionsHref}
      videoTagClassNames={['vjs-big-play-centered']}
      onReady={handleReady}
    />,
    [thumbnailHref, videoSources, captionsHref, handleReady],
  );
  return videoPlayer;
};

const ThinkingTimeArea = ({ remainingTime, onAnswerNow }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const hasUnlimitedThinkingTime = _.isUndefined(remainingTime) || _.isNull(remainingTime);
  return (
    <Box display={isExtraSmall ? "block" : "flex"} alignItems={isExtraSmall ? "normal" : "center"}>
      {
        !hasUnlimitedThinkingTime &&
        <>
          <Typography variant="overline">
            {t('Have a think for')}
          </Typography>&nbsp;&nbsp;
          <Typography variant="overline">
            <span style={{ fontWeight: 'bold' }}>{toMMSS(remainingTime)}</span>
          </Typography>&nbsp;&nbsp;&nbsp;
          <Typography variant="overline">
            {t('or')}
          </Typography>&nbsp;&nbsp;&nbsp;&nbsp;
          {isExtraSmall && <br />}
        </>
      }
      <Button
        size="large"
        variant="contained"
        color="secondary"
        onClick={onAnswerNow}
      >
        {t('Answer now')}
      </Button>
    </Box>
  );
};

const ThinkingTimeBackdrop = styled(({ open, remainingTime, onAnswerNow, ...props }) => {
  const { t } = useTranslation();
  const hasUnlimitedThinkingTime = _.isUndefined(remainingTime) || _.isNull(remainingTime);
  return (
    <Backdrop open={open} {...props}>
      <Box display="flex" flexDirection="column" textAlign="center">
        {
          !hasUnlimitedThinkingTime
          &&
          <>
            <Typography variant="h6" gutterBottom>
              {t('Have a think for')}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {toMMSS(remainingTime)}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('or')}
            </Typography>
          </>
        }
        <Box mt={2}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={onAnswerNow}
          >
            {t('Answer now')}
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
})({
  zIndex: 1099,
  color: '#ffffff',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const WatchQuestion = ({ questionIndex, questions, pacing, onStartAnswer }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const question = questions[questionIndex];
  const hasVideo = !!question.video;
  const hasQuestionText = !!question.title || !!question.content;
  const hasUnlimitedThinkingTime = _.isUndefined(pacing.thinkingTime) || _.isNull(pacing.thinkingTime) || pacing.thinkingTime < 0;
  const hasNoThinkingTime = !hasUnlimitedThinkingTime && pacing.thinkingTime <= 0;
  const [countdownActive, setCountdownActive] = useState(!hasVideo);
  const [remainingTime, setRemainingTime] = useState(!hasUnlimitedThinkingTime ? pacing.thinkingTime : null);

  const nextTick = useCallback(() => {
    if (!countdownActive || hasUnlimitedThinkingTime) {
      return;
    } else if (remainingTime <= 1) {
      setCountdownActive(false);
      onStartAnswer();
    } else {
      setRemainingTime(currentTime => currentTime - 1);
    }
  }, [countdownActive, hasUnlimitedThinkingTime, remainingTime, setCountdownActive, onStartAnswer, setRemainingTime]);

  useEffect(() => {
    const timerId = setInterval(nextTick, 1000);
    return () => clearInterval(timerId);
  }, [nextTick]);

  const handleVideoEnded = useCallback(() => {
    if (hasNoThinkingTime) {
      onStartAnswer();
    } else {
      setCountdownActive(true);
    }
  }, [hasNoThinkingTime, onStartAnswer, setCountdownActive]);

  const handleSkipWait = useCallback(() => {
    setCountdownActive(false);
    onStartAnswer();
  }, [setCountdownActive, onStartAnswer]);

  return (
    <InterviewPage maxWidth={!hasVideo ? "md" : (hasQuestionText ? "lg" : "md")}>
      <Box pl={isExtraSmall ? 3 : 0} pr={isExtraSmall ? 3 : 0}>
        <Grid container spacing={2}>
          {
            hasVideo &&
            <Grid item xs={12} sm={hasQuestionText ? 6 : 12}>
              <Box style={{ borderRadius: '4px', overflow: 'hidden', position: 'relative' }}>
                <QuestionVideo thumbnail={question.thumbnail} video={question.video} onVideoEnded={handleVideoEnded} />
                <ThinkingTimeBackdrop open={countdownActive} remainingTime={remainingTime} onAnswerNow={handleSkipWait} />
              </Box>
            </Grid>
          }
          {
            hasQuestionText &&
            <Grid item xs={12} sm={hasVideo ? 6 : 12}>
              <Box
                mt={isExtraSmall ? 2 : 0}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "100%" }}
              >
                <Box
                  textAlign="left"
                  pt={!hasVideo ? 5 : 0}
                  pr={(!hasVideo && isSmall) ? 5 : 0}
                  pb={!hasVideo ? 5 : 0}
                  pl={(!hasVideo && isSmall) ? 5 : 0}
                >
                  <Typography variant="overline">
                    <Trans>
                      Question {{ current: questionIndex + 1 }} of {{ total: questions.length }}
                    </Trans>
                  </Typography>
                  <Typography variant={hasVideo ? "h6" : "h5"} gutterBottom>
                    {question.title}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {question.content}
                  </Typography>
                  {
                    !hasVideo &&
                    <Box mt={isExtraSmall ? 2 : 3}>
                      <ThinkingTimeArea remainingTime={remainingTime} onAnswerNow={handleSkipWait} />
                    </Box>
                  }
                </Box>
              </Box>
            </Grid>
          }
        </Grid>
      </Box>
    </InterviewPage>
  );
};

const QuestionProgress = ({ message }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box m={!isExtraSmall ? 5 : 0} display="flex" flexDirection="column" alignItems="center">
      <CircularProgress color="primary" />
      <Box mt={2}>
        <Typography variant="h6">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

const QuestionPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { shortcode, index } = useParams();
  const questionIndex = parseInt(index);
  const { isPreview, candidateConfig, candidateInterview } = useCandidateInterview(shortcode);
  const [isAnswering, setIsAnswering] = useState(false);
  const [attemptNumber, setAttemptNumber] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();

  useEffect(() => {
    if (candidateInterview && candidateInterview.currentStatus === 'completed') {
      history.push(`/interview/${shortcode}/outro`);
    }
  }, [candidateInterview, history, shortcode]);

  useEffect(() => {
    if (candidateInterview) {
      const savedAnswers = api.getSavedAnswers(shortcode);
      const nextIndex = Math.min(
        savedAnswers.length,
        candidateInterview.questions.length - 1,
      );
      if (nextIndex !== questionIndex) {
        history.push(`/interview/${shortcode}/questions/${nextIndex}`);
      }
    }
  }, [candidateInterview, questionIndex, history, shortcode]);

  if (!candidateInterview) {
    return <CenteredLoading message={t('Loading question...')} />
  }

  const numberQuestions = candidateInterview.questions.length;
  const isFinalQuestion = questionIndex >= numberQuestions - 1;

  if (questionIndex >= numberQuestions) {
    return (
      <CenteredContent>
        <Alert severity="error">
          <AlertTitle>{t('Question not found')}</AlertTitle>
          {t("We're unable to find the question you've requested.")}
        </Alert>
      </CenteredContent>
    );
  }

  const handleStartAnswer = () => {
    setIsAnswering(true);
  };

  const handleRetakeQuestion = () => {
    setAttemptNumber(currentAttempt => currentAttempt + 1);
    setIsAnswering(false);
  };

  const submitAnswers = async () => {
    setIsSubmitting(true);
    setSubmitError(null);
    setIsAnswering(false);
    try {
      if (isPreview) {
        // Make the preview feel realistic
        await timeout(2000);
        api.flushSavedAnswers(shortcode);
      } else {
        const startTimestamp = storage.getItem('canvass-start-timestamp');
        const stopTimestamp = new Date().getTime() / 1000;
        const tookSeconds = stopTimestamp - parseInt(startTimestamp, 10);

        if (candidateInterview.currentStatus === 'open') {
          await api.submitApplication(shortcode, candidateInterview.interviewId, tookSeconds);
        } else {
          await api.submitInterview(shortcode, tookSeconds);
        }
      }
      history.push(`/interview/${shortcode}/outro`);
    } catch (error) {
      setSubmitError(error);
    }
  };

  const handleSubmittingTryAgain = () => {
    submitAnswers();
  };

  const handleFinishAnswer = answer => {
    api.saveAnswer(shortcode, questionIndex, answer);
    if (isFinalQuestion) {
      submitAnswers();
    } else {
      setIsAnswering(false);
      history.push(`/interview/${shortcode}/questions/${questionIndex + 1}`);
    }
  };

  if (isSubmitting) {
    return (
      <>
        <InterviewPage maxWidth="sm">
          <Box pl={3} pr={3}>
            <QuestionProgress message={t('Submitting your interview...')} />
          </Box>
        </InterviewPage>
        <Dialog open={!!submitError}>
          <DialogTitle>{t("Unable to submit interview")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans>
                Unable to submit your interview to our platform. Please try again and contact
                us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for assistance
                if the problem persists.
              </Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmittingTryAgain} color="primary">
              {t('Try again')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else if (isAnswering) {
    return (
      <AnswerQuestion
        isPreview={isPreview}
        preferTranscoder={candidateConfig?.preferTranscoder}
        shortcode={shortcode}
        questionIndex={questionIndex}
        isFinalQuestion={isFinalQuestion}
        attemptNumber={attemptNumber}
        pacing={candidateInterview.pacing}
        onRetakeQuestion={handleRetakeQuestion}
        onFinishAnswer={handleFinishAnswer}
      />
    );
  } else {
    return (
      <WatchQuestion
        questionIndex={questionIndex}
        questions={candidateInterview.questions}
        pacing={candidateInterview.pacing}
        onStartAnswer={handleStartAnswer}
      />
    );
  }
};

export default QuestionPage;