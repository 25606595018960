import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router';
import Bowser from "bowser";

import InterviewPage from '../InterviewPage';
import CenteredLoading from '../CenteredLoading';
import VideoPlayer from '../VideoPlayer';
import utils from '../../utils';
import useCandidateInterview from '../../hooks/useCandidateInterview';
import api from '../../API';
import storage from '../../storage';
import { LogoContext } from '../../context';

const IntroVideo = ({ thumbnail, video }) => {
  const { thumbnailHref, videoSources, captionsHref } = utils.extractHrefsForVideoPlayer(thumbnail, video);
  const videoPlayer = useMemo(() =>
    <Box style={{ borderRadius: '4px', overflow: 'hidden' }}>
      <VideoPlayer
        options={{
          controls: true,
          fluid: true,
          poster: thumbnailHref,
          sources: videoSources,
          controlBar: {
            volumePanel: false,
            pictureInPictureToggle: false,
          },
        }}
        captionsHref={captionsHref}
        videoTagClassNames={['vjs-big-play-centered']}
      />
    </Box>,
    [thumbnailHref, videoSources, captionsHref],
  );
  return videoPlayer;
};

const IntroPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const { shortcode } = useParams();
  const { candidateInterview } = useCandidateInterview(shortcode);
  const logoImageHref = useContext(LogoContext)
  const [showUnsupported, setShowUnsupported] = useState(false);

  const browser = Bowser.getParser(window.navigator.userAgent);
  const isBadiOS = browser.getOSName() === 'iOS' && parseInt(browser.getOSVersion(), 10) < 13;
  const unsupportedDeviceMessage = isBadiOS
    ? t("We're unable to support iOS devices below version 13.", { version: browser.getOSVersion() })
    : null;

  useEffect(() => {
    if (candidateInterview && candidateInterview.currentStatus === 'completed') {
      history.push(`/interview/${shortcode}/outro`);
    }
  }, [candidateInterview, history, shortcode]);

  useEffect(() => {
    if (candidateInterview) {
      const savedAnswers = api.getSavedAnswers(shortcode);
      if (savedAnswers.length > 0) {
        const nextIndex = Math.min(
          savedAnswers.length,
          candidateInterview.questions.length - 1,
        );
        history.push(`/interview/${shortcode}/questions/${nextIndex}`);
      }
    }
  }, [candidateInterview, history, shortcode]);

  if (!candidateInterview) {
    return <CenteredLoading message={t('Loading intro...')} />
  }

  const handleStartInterview = () => {
    if (unsupportedDeviceMessage) {
      setShowUnsupported(true);
    } else {
      const startTimestamp = new Date().getTime() / 1000;
      storage.setItem('canvass-start-timestamp', startTimestamp.toString());

      if (candidateInterview.currentStatus === 'open') {
        history.push(`/interview/${shortcode}/details`);
      } else {
        history.push(`/interview/${shortcode}/select-audio-video`);
      }
    }
  };

  const handleCloseUnsupportedDialog = () => {
    setShowUnsupported(false);
  };

  const intro = candidateInterview.intro;
  const hasIntroVideo = !!intro;
  const hasLogo = !!logoImageHref;
  const showLogo = hasLogo && !isExtraSmall;
  const numberQuestions = candidateInterview.questions.length;

  return (
    <>
      <InterviewPage maxWidth={hasIntroVideo ? "lg" : "md"}>
        <Box pl={isExtraSmall ? 3 : 0} pr={isExtraSmall ? 3 : 0}>
          <Grid container spacing={2}>
            {
              hasIntroVideo &&
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" style={{ minHeight: "100%" }}>
                  <IntroVideo thumbnail={intro.thumbnail} video={intro.video} />
                </Box>
              </Grid>
            }
            <Grid item xs={12} sm={hasIntroVideo ? 6 : 12}>
              <Box display="flex" flexDirection="column-reverse" alignItems="center" style={{ minHeight: "100%" }}>
                <Box textAlign="center" mt={4}>
                  <Button size="large" variant="contained" color="primary" onClick={handleStartInterview}>
                    {t('Start interview')}
                  </Button>
                  <Box mt={1} color="text.disabled">
                    <Typography variant="caption">
                      <Trans>
                        By continuing you agree to our <Link href={process.env.REACT_APP_CANVASS_TOS_HREF} target="_blank">Terms of Service</Link> and <Link href={process.env.REACT_APP_CANVASS_PRIVACY_POLICY_HREF} target="_blank">Privacy Policy</Link>
                      </Trans>
                    </Typography>
                  </Box>
                </Box>
                <Box textAlign="center" flexGrow={1} mt={isExtraSmall ? 2 : (isLarge && hasIntroVideo && !showLogo ? 6 : (isMedium && hasIntroVideo && !showLogo ? 3 : 0))}>
                  {
                    showLogo &&
                    <img src={logoImageHref} width="100px" alt={t('Logo')} />
                  }
                  <Typography variant="h5" gutterBottom>
                    {t('Video Interview')}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {showLogo && candidateInterview.title.jobTitle}
                    {
                      !showLogo &&
                      <Trans>
                        {{ jobTitle: candidateInterview.title.jobTitle }} at {{ companyName: candidateInterview.title.companyName }}
                      </Trans>
                    }
                  </Typography>
                  <Typography variant="overline">
                    {
                      numberQuestions === 1
                        ? t('1 question')
                        : t('{{numberQuestions}} questions', { numberQuestions })
                    }
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </InterviewPage>
      <Dialog open={showUnsupported} onClose={handleCloseUnsupportedDialog}>
        <DialogTitle>{t("Unsupported device")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {unsupportedDeviceMessage}
            <br /><br />
            <Trans>
              Please use an alternate phone, tablet, or computer with a user-facing camera, or
              contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
              further assistance.
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUnsupportedDialog} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IntroPage;