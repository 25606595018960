import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Bowser from "bowser";
import getBrowserRtc from 'get-browser-rtc';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Grid, LinearProgress, Typography, useMediaQuery } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import MicIcon from '@material-ui/icons/Mic';
import { useParams } from 'react-router';
import Webcam from "react-webcam";

import AspectRatio from '../AspectRatio';
import FullWidthSelectControl from '../FullWidthSelectControl';
import CenteredContent from '../CenteredContent';
import CenteredLoading from '../CenteredLoading';
import InterviewPage from '../InterviewPage';
import useCandidateInterview from '../../hooks/useCandidateInterview';
import useCheckMediaRecorder from '../../hooks/useCheckMediaRecorder';
import useVolumeLevel from '../../hooks/useVolumeLevel';

import storage from '../../storage';

const WEBRTC_SUPPORT = !!getBrowserRtc();

const BASE_AUDIO_CONSTRAINTS = {
  autoGainControl: false,
  channelCount: 2,
  echoCancellation: false,
  latency: 0,
  noiseSuppression: false,
  sampleRate: 48000,
  sampleSize: 16,
  volume: 1.0
};

const BASE_VIDEO_CONSTRAINTS = {
  facingMode: 'user',
};

const SelectInputsArea = ({ mediaStream, onUpdated, ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [devices, setDevices] = useState([]);
  const [audioDeviceId, setAudioDeviceId] = useState();
  const [videoDeviceId, setVideoDeviceId] = useState();

  const buildDeviceLabel = device => {
    if (device.deviceId === 'default') {
      return t('{{deviceLabel}} (default)', { deviceLabel: device.label });
    }

    return device.label;
  };

  const handleDevices = useCallback(mediaDevices => {
    setDevices(mediaDevices);
  }, [setDevices]);

  useEffect(() => {
    // mediaStream included as dependency to address issue with Safari:
    // https://stackoverflow.com/questions/51387564/navigator-mediadevices-enumeratedevices-only-returns-default-devices-on-safari
    const canEnumerateDevices = !!navigator?.mediaDevices?.enumerateDevices;
    if (canEnumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
  }, [handleDevices, mediaStream]);

  useEffect(() => {
    if (mediaStream) {
      const audioTracks = mediaStream.getAudioTracks();
      if (audioTracks && audioTracks.length > 0) {
        const settings = audioTracks[0].getSettings();
        setAudioDeviceId(settings.deviceId);
      }
    }
  }, [mediaStream, setAudioDeviceId]);

  useEffect(() => {
    if (mediaStream) {
      const videoTracks = mediaStream.getVideoTracks();
      if (videoTracks && videoTracks.length > 0) {
        const settings = videoTracks[0].getSettings();
        setVideoDeviceId(settings.deviceId);
      }
    }
  }, [mediaStream, setVideoDeviceId]);

  const audioDevices = devices.filter(device => device.kind === 'audioinput');
  const audioOptions = audioDevices.map(
    device => [buildDeviceLabel(device), device.deviceId]
  );

  const videoDevices = devices.filter(device => device.kind === 'videoinput');
  const videoOptions = videoDevices.map(
    device => [buildDeviceLabel(device), device.deviceId]
  );

  const handleAudioDeviceChanged = (event) => {
    const newAudioDeviceId = event.target.value;
    setAudioDeviceId(newAudioDeviceId);
    onUpdated({
      audioDeviceId: newAudioDeviceId,
      videoDeviceId,
    });
  };

  const handleVideoDeviceChanged = (event) => {
    const newVideoDeviceId = event.target.value;
    setVideoDeviceId(newVideoDeviceId);
    onUpdated({
      audioDeviceId,
      videoDeviceId: newVideoDeviceId,
    });
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" {...props}>
      <Box width="100%" maxWidth={isExtraSmall ? "100%" : "450px"}>
        <FullWidthSelectControl
          label={t('Audio')}
          labelId="select-audio-label"
          value={audioDeviceId}
          onChange={handleAudioDeviceChanged}
          options={audioOptions}
        />
      </Box>
      <Box mt={3} width="100%" maxWidth={isExtraSmall ? "100%" : "450px"}>
        <FullWidthSelectControl
          label={t('Video')}
          labelId="select-video-label"
          value={videoDeviceId}
          onChange={handleVideoDeviceChanged}
          options={videoOptions}
        />
      </Box>
    </Box>
  );
};

const SelectAudioVideo = ({ shortcode, onClickContinue }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const webcamRef = useRef(null);
  const [mediaStream, setMediaStream] = useState();
  const { isPreview } = useCandidateInterview(shortcode);
  // Assume it's working by default..
  const [isMediaRecorderWorking, setIsMediaRecorderWorking] = useState(true);
  const { isVolumeLevelAvailable, volumeLevel } = useVolumeLevel(mediaStream);
  const [audioDeviceId, setAudioDeviceId] = useState(
    storage.getItem('canvass-audio-device-id'),
  );
  const [videoDeviceId, setVideoDeviceId] = useState(
    storage.getItem('canvass-video-device-id'),
  );
  const [isConnecting, setIsConnecting] = useState(true);
  const [videoSizeProps, setVideoSizeProps] = useState({});
  const [connectError, setConnectError] = useState();
  const {
    error: recorderError,
    contentType: supportedContentType,
  } = useCheckMediaRecorder();
  const canUseRecorderService = WEBRTC_SUPPORT;

  const audioConstraints = useMemo(() => {
    const constraints = { ...BASE_AUDIO_CONSTRAINTS };
    if (audioDeviceId) {
      constraints.deviceId = audioDeviceId;
    }
    return constraints;
  }, [audioDeviceId]);

  const videoConstraints = useMemo(() => {
    const constraints = { ...BASE_VIDEO_CONSTRAINTS };

    const browserSettings = Bowser.parse(window.navigator.userAgent);
    if (browserSettings?.platform?.type === 'desktop') {
      constraints.width = 852;
      constraints.height = 480;
      constraints.aspectRatio = 1.777777778;
    }

    if (videoDeviceId) {
      constraints.deviceId = videoDeviceId;
    }

    return constraints;
  }, [videoDeviceId]);

  useEffect(() => {
    let mediaRecorder = null;

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state === 'recording') {
        mediaRecorder.stop();
      }
    };

    if (mediaStream) {
      setIsMediaRecorderWorking(false);

      mediaRecorder = new MediaRecorder(mediaStream);
      mediaRecorder.addEventListener('dataavailable', event => {
        if (event.data && event.data.size > 0) {
          setIsMediaRecorderWorking(true);
          stopRecording();
        }
      });
      mediaRecorder.start(250);
    }
    return () => {
      stopRecording();
    };
  }, [supportedContentType, mediaStream, setIsMediaRecorderWorking]);

  const handleUserMedia = mediaStream => {
    setIsConnecting(false);
    setMediaStream(mediaStream);
    const videoTracks = mediaStream.getVideoTracks();
    const settings = videoTracks[0].getSettings();
    const sizeProps = (isExtraSmall || !settings.aspectRatio || settings.aspectRatio <= 1)
      ? { height: '100%' }
      : { width: '100%' };
    setVideoSizeProps(sizeProps);
  };

  const handleUserMediaError = (error) => {
    setIsConnecting(false);
    setConnectError(error);
  };

  const handleInputsUpdated = ({ audioDeviceId, videoDeviceId }) => {
    storage.setItem('canvass-audio-device-id', audioDeviceId);
    setAudioDeviceId(audioDeviceId);
    storage.setItem('canvass-video-device-id', videoDeviceId);
    setVideoDeviceId(videoDeviceId);
  };

  const handleClickContinue = () => {
    onClickContinue(isMediaRecorderWorking);
  };

  if (!isPreview && recorderError && !canUseRecorderService) {
    return (
      <CenteredContent>
        <Alert severity="error">
          <AlertTitle>{recorderError}</AlertTitle>
          <Trans>
            We're unable to record video in your browser. Please try another
            browser or device. Don't hesitate to contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
            further assistance.
          </Trans>
        </Alert>
      </CenteredContent>
    );
  }

  if (connectError) {
    return (
      <CenteredContent>
        <Alert severity="error">
          <AlertTitle>{connectError.message}</AlertTitle>
          <Trans>
            Unable to access your camera or microphone. Please ensure that you've
            granted the necessary permissions in your browser settings. Don't
            hesitate to contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
            further assistance.
          </Trans>
        </Alert>
      </CenteredContent>
    );
  }

  return (
    <InterviewPage maxWidth="lg">
      <Box pl={isExtraSmall ? 3 : 0} pr={isExtraSmall ? 3 : 0} display={isConnecting ? "none" : "block"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              style={{ minHeight: "100%" }}
              fontSize={0}
            >
              <AspectRatio horizontal={16} vertical={9}>
                <Webcam
                  muted
                  ref={webcamRef}
                  audio={true}
                  mirrored={true}
                  audioConstraints={audioConstraints}
                  videoConstraints={videoConstraints}
                  onUserMedia={handleUserMedia}
                  onUserMediaError={handleUserMediaError}
                  {...videoSizeProps}
                />
              </AspectRatio>
              {
                isVolumeLevelAvailable &&
                <Box mt={2} display="flex" alignItems="center" width="100%">
                  <Box pl={1} pr={1}>
                    <MicIcon />
                  </Box>
                  <Box flexGrow={1} pr={2}>
                    <LinearProgress variant="determinate" value={volumeLevel} />
                  </Box>
                </Box>
              }
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {
              !isConnecting
              &&
              <Box
                mt={isExtraSmall ? 2 : 0}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                style={{ minHeight: "100%" }}
              >
                <Typography variant="h6" gutterBottom>
                  {t('Verify your audio and video')}
                </Typography>
                {
                  isVolumeLevelAvailable &&
                  <Box width="100%" maxWidth={isExtraSmall ? "100%" : "450px"}>
                    <Typography variant="body1">
                      {t('Please ensure you can see yourself on camera and the volume meter moves when you speak.')}
                    </Typography>
                  </Box>
                }
                <SelectInputsArea
                  mt={4}
                  mb={4}
                  mediaStream={mediaStream}
                  onUpdated={handleInputsUpdated}
                />
                <Button size="large" variant="contained" color="primary" onClick={handleClickContinue}>
                  {t('Continue')}
                </Button>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>
      {
        isConnecting &&
        <QuestionProgress message={t('Connecting to your camera...')} />
      }
    </InterviewPage>
  );
};

const QuestionProgress = ({ message }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box m={!isExtraSmall ? 5 : 0} display="flex" flexDirection="column" alignItems="center">
      <CircularProgress color="primary" />
      <Box mt={2}>
        <Typography variant="h6">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

const SelectAudioVideoPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { shortcode } = useParams();
  const { candidateInterview } = useCandidateInterview(shortcode);

  useEffect(() => {
    if (candidateInterview && candidateInterview.currentStatus === 'completed') {
      history.push(`/interview/${shortcode}/outro`);
    }
  }, [candidateInterview, history, shortcode]);

  if (!candidateInterview) {
    return <CenteredLoading message={t('Loading devices...')} />
  }

  const handleClickContinue = isMediaRecorderWorking => {
    const firstQuestionIndex = 0;
    storage.setItem('canvass-force-recorder-service', !isMediaRecorderWorking ? 'true' : 'false');
    history.push(`/interview/${shortcode}/questions/${firstQuestionIndex}`);
  };

  return (
    <SelectAudioVideo
      shortcode={shortcode}
      onClickContinue={handleClickContinue}
    />
  );
};

export default SelectAudioVideoPage;