import { useTheme } from '@material-ui/core/styles';
import { Box, Paper, useMediaQuery } from '@material-ui/core';

import CenteredContent from './CenteredContent';

const InterviewPage = ({ maxWidth, children }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  if (isExtraSmall) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{ position: 'absolute', 'top': 0, 'right': 0, 'bottom': 0, 'left': 0 }}
      >
        {children}
      </Box>
    );
  } else {
    return (
      <CenteredContent maxWidth={maxWidth}>
        <Paper style={{ width: '100%' }}>
          <Box p={3}>
            {children}
          </Box>
        </Paper>
      </CenteredContent>
    );
  }
};

export default InterviewPage;