import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import CenteredContent from './CenteredContent';

const CenteredLoading = ({ message }) => {
  return (
    <CenteredContent>
      <CircularProgress />
      <Box mt={1}>
        <Typography variant="h6">
          {message}
        </Typography>
      </Box>
    </CenteredContent>
  );
};

export default CenteredLoading;