/*
 * localStorage sometimes isn't available for candidates, which leads
 * to them being unable to submit their interview! We'll proxy to memoryStorage
 * so they're at least able to submit if they've not refreshed the page...
 */
const memoryStorage = {};

const setItem = (key, value) => {
  memoryStorage[key] = value;
  localStorage.setItem(key, value);
};

const getItem = (key) => {
  if(memoryStorage.hasOwnProperty(key)) {
    return memoryStorage[key];
  }
  return localStorage.getItem(key);
};

const removeItem = (key) => {
  delete memoryStorage[key];
  localStorage.removeItem(key);
};

const storage = { setItem, getItem, removeItem };

export default storage;
