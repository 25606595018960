import { useEffect, useState } from 'react';
import { trackError } from '../errors';

const AudioContext = window.AudioContext || window.webkitAudioContext;

const useVolumeLevel = mediaStream => {
  const [volumeLevel, setVolumeLevel] = useState(null);
  const isVolumeLevelAvailable = volumeLevel !== null;

  useEffect(() => {
    let intervalID;

    try {
      if (mediaStream && AudioContext) {
        const audioTracks = mediaStream.getAudioTracks();
        if (audioTracks && audioTracks.length > 0) {
          const audioContext = new AudioContext();
          const audioSource = audioContext.createMediaStreamSource(mediaStream);
          const analyser = audioContext.createAnalyser();
          audioSource.connect(analyser);

          const pcmData = new Float32Array(analyser.fftSize);
          const bytesPcmData = new Uint8Array(analyser.fftSize);
          const fillPCMData = () => {
            if (analyser.getFloatTimeDomainData) {
              analyser.getFloatTimeDomainData(pcmData);
            } else {
              // https://stackoverflow.com/a/68465576
              analyser.getByteTimeDomainData(bytesPcmData);
              for (let t = 0; t < analyser.fftSize; t++) {
                pcmData[t] = .0078125 * (bytesPcmData[t] - 128);
              }
            }
          };

          const updateVolumeLevel = () => {
            // https://jameshfisher.com/2021/01/18/measuring-audio-volume-in-javascript/
            try {
              fillPCMData();
              let sumSquares = 0.0;
              for (const amplitude of pcmData) {
                sumSquares += amplitude * amplitude;
              }
              const newVolumeLevel = Math.min(
                // N.B. the 1000 multiplier is to make the meter more sensitive and it's
                // value itself is arbitrary. 1000 simply felt 'good' when testing...
                Math.sqrt(sumSquares / pcmData.length) * 1000,
                100
              );
              setVolumeLevel(newVolumeLevel);
            } catch (error) {
              // Don't let errors crash the whole page...
              setVolumeLevel(null);
              trackError(error);
            }
          };

          intervalID = setInterval(updateVolumeLevel, 100);
        }
      }
    } catch (error) {
      // Don't let errors crash the whole page...
      setVolumeLevel(null);
      trackError(error);
    }
    return () => {
      clearInterval(intervalID);
    };
  }, [mediaStream, setVolumeLevel]);

  return { isVolumeLevelAvailable, volumeLevel };
};

export default useVolumeLevel;