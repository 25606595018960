import { useEffect, useState } from 'react';
import api from '../API';

const useCandidateInterview = shortcode => {
  const [interviewState, setInterviewState] = useState({});

  useEffect(() => {
    const fetchData = async () =>
      setInterviewState(await api.fetchCandidateInterview(shortcode, true));

    fetchData();
  }, [shortcode, setInterviewState]);

  return interviewState;
};

export default useCandidateInterview;