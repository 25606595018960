import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Alert, AlertTitle } from '@material-ui/lab';
import CenteredContent from '../CenteredContent';
import CenteredLoading from '../CenteredLoading';

import api from '../../API';

const LoadInterviewPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { shortcode } = useParams();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { candidateInterview } = await api.fetchCandidateInterview(shortcode, false);
        switch (candidateInterview.currentStatus) {
          case 'sent':
          case 'open':
            history.push(`/interview/${shortcode}/intro`);
            break;
          case 'completed':
            history.push(`/interview/${shortcode}/outro`);
            break;
          default:
            throw new Error(`Unexpected status: ${candidateInterview.currentStatus}`);
        }
      } catch (error) {
        setError({
          title: t('Interview not found'),
          message: t("We're unable to find the interview you've requested."),
        });
      }
    };

    fetchData();
  }, [t, shortcode, history, setError]);

  if (error) {
    return (
      <CenteredContent>
        <Alert severity="error">
          <AlertTitle>{error.title}</AlertTitle>
          {error.message}
        </Alert>
      </CenteredContent>
    );
  }

  return <CenteredLoading message={t('Loading interview...')} />
};

export default LoadInterviewPage;