import React from 'react';
import { Box, Container } from '@material-ui/core';

const CenteredContent = ({ children, maxWidth, ...props }) => {
  if (!maxWidth) {
    maxWidth = 'xs';
  }
  return (
    <Container maxWidth={maxWidth}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: '100vh' }}
        {...props}
      >
        {children}
      </Box>
    </Container>
  );
};

export default CenteredContent;