import _ from 'lodash';

const extractCaptionsHref = video => video?.captions?.vttHref || null;

const extractVideoSources = video =>
  !_.isNull(video) && !_.isUndefined(video)
    ? video.variants.map(
      video => ({
        src: video.contentHref,
        type: video.contentType,
      })
    )
    : null;

const extractThumbnailHref = thumbnail => {
  if (_.isNull(thumbnail) || _.isUndefined(thumbnail)) {
    return null;
  }
  // Take priority order from the backend
  return thumbnail.variants[0].contentHref;
};

const extractHrefsForVideoPlayer = (thumbnail, video) => {
  const thumbnailHref = extractThumbnailHref(thumbnail);
  const videoSources = extractVideoSources(video);
  const captionsHref = extractCaptionsHref(video);
  return { thumbnailHref, videoSources, captionsHref }
};

const utils = { extractHrefsForVideoPlayer };

export default utils;
